.stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem;
  gap: 2rem;
}

.bumper {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: whitesmoke;
  color: #121212;
  padding: 0.25rem 1.5rem;
  border-radius: 5rem;
  width: fit-content;
}

.header {
  font-family: "Geostar Fill";
  font-size: 48px;
}

.subheader {
  font-weight: 500;
}
