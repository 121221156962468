body {
  background-color: #121212;
  color: whitesmoke;
  font-family: "JetBrains Mono";
  font-weight: 300;
  font-size: 12px;
}

a {
  color: orange;
}
